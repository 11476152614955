import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useGoogleLogin } from '@react-oauth/google';
import { useSWRConfig } from 'swr';
import type { Nullable } from 'types/common';
import { isError } from 'types/predicates';

import { isInvalidCredentialsError, setAuth } from 'services/auth-new';

import logSentryError from 'utils/sentry';

import { useGoogleSigninMutation, useProseSigninMutation } from 'dux/api/auth';

import useNextFromLocationSearchString from '../useNextFromLocationSearchString';
import type { SigninForm } from './types';
import getAuthErrorMessage, { isRegularObject } from './utils';

// todo: follow the doc and ensure equivalency with the old code
export const useGoogleAuthLogin = () => {
  const navigate = useNavigate();
  const next = useNextFromLocationSearchString() ?? '/';

  const [googleSignin] = useGoogleSigninMutation();
  const [errorGoogleMessage, setErrorGoogleMessage] = useState<string | undefined>('');

  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        setErrorGoogleMessage('');
        const auth = await googleSignin(code).unwrap();
        setAuth(auth);
        navigate(next);
      } catch (err) {
        if (!isError(err) && !isRegularObject(err) && typeof err !== 'string') return;

        setErrorGoogleMessage(getAuthErrorMessage(err));
        logSentryError('[SignIn] GoogleSignin', getAuthErrorMessage(err));
      }
    },
    onError: ({ error_description }) => {
      setErrorGoogleMessage(error_description ?? 'Google sign in error');
    },
    flow: 'auth-code',
  });

  return { login, errorGoogleMessage };
};

export const useProseLogin = () => {
  const navigate = useNavigate();
  const next = useNextFromLocationSearchString() ?? '/'; // todo: add e2e
  const [proseSignIn, { status }] = useProseSigninMutation();
  const [errorMessage, setErrorMessage] = useState<Nullable<string> | undefined>(null);

  const { mutate } = useSWRConfig();

  const login = async (e: React.FormEvent<HTMLFormElement> & SigninForm) => {
    e.preventDefault();
    try {
      setErrorMessage(null);
      await mutate(() => true, undefined, false); // Clear all the cache. SWR will revalidate upon re-render.
      const username = e.target?.username.value;
      const password = e.target?.password.value;
      const auth = await proseSignIn({ username, password }).unwrap();
      setAuth(auth);
      navigate(next);
    } catch (err) {
      const errorMsg = getAuthErrorMessage(err);
      setErrorMessage(errorMsg);
      if (!isInvalidCredentialsError(err)) {
        logSentryError('[Signin hooks] Prose signin', errorMsg);
      }
    }
  };

  return { login, fetchingStatus: status, errorMessage };
};
