import React, { type ReactNode, useEffect } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import forEach from 'lodash/fp/forEach';
import size from 'lodash/fp/size';

import { useOmsDispatch, useOmsSelector } from 'hooks/rtk';

import { defaultSettings } from '../Apps/Settings/content';

import { getAuthSafe, getRedirectUrl, isSignedIn } from 'services/auth-new';

import { loadParam, saveParam } from 'utils/localStorage';

import * as ProductionActions from 'dux/production/actions';
import * as TeammatesSelectors from 'dux/teammates/selectors';
import * as FeatureFlagsActions from 'dux/featureFlags/slice';

const consolidateSettings = () => {
  const settings = loadParam('settings') || defaultSettings;
  forEach(defaultKey => {
    if (!settings[defaultKey]) {
      settings[defaultKey] = defaultSettings[defaultKey];
    }
  }, defaultSettings);
  saveParam('settings', settings);
};

type Props = { children: ReactNode };

const Authentication = ({ children }: Props) => {
  const { pathname } = useLocation();

  const dispatch = useOmsDispatch();
  const { accessToken } = getAuthSafe();
  const isUserSignedIn = isSignedIn();
  const factories = useOmsSelector(TeammatesSelectors.getSignedInTeammateProductionFactories);

  // feature flags handler
  useEffect(() => {
    dispatch(FeatureFlagsActions.fetchFeatureFlags());
  }, [dispatch, isUserSignedIn]);

  // factories handler
  useEffect(() => {
    if (isUserSignedIn) {
      dispatch(
        ProductionActions.setFactory({
          factory: size(factories) !== 1 ? 'all' : factories[0],
          mode: '',
        })
      );
    }
  }, [dispatch, factories, isUserSignedIn]);

  useEffect(() => {
    // todo: keep it for now and don't touch API client? "if (isAccessExpired()) -> refresh auth"?
    consolidateSettings();
  }, []);

  if (!accessToken) return <Navigate to={getRedirectUrl(pathname)} />;

  return children;
};

export default Authentication;
